import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import titleLogo from "../images/BollyCardsTitle.png";
import { THEME } from "../constants/app";

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "30px",
  [THEME.breakpoints.down("sm")]: {
    paddingTop: "15px",
  },
});

const HeaderLogo = styled.img({
  height: "50px",
  cursor: "pointer",
});

const Header = () => {
  const history = useHistory();

  return (
    <Container>
      <HeaderLogo
        src={titleLogo}
        alt="BollyCards"
        onClick={() => history.push("/")}
      />
    </Container>
  );
};

export default Header;
