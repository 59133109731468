import React from "react";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import { Facebook, Instagram, Email } from "@material-ui/icons";

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Contacts = () => (
  <Container>
    <Link
      href="https://www.facebook.com/BollyCardsGame"
      target="_blank"
      rel="noopener noreferrer"
      component={IconButton}
    >
      <Facebook fontSize="large" />
    </Link>
    <Link
      href="https://www.instagram.com/bollycards"
      target="_blank"
      rel="noopener noreferrer"
      component={IconButton}
    >
      <Instagram fontSize="large" />
    </Link>
    <Link
      href="mailto:bollycards@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
      component={IconButton}
    >
      <Email fontSize="large" />
    </Link>
  </Container>
);

export default Contacts;
