import styled from "styled-components";

const FooterLinks = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "12px",
});

export default FooterLinks;
