import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";

import Contacts from "../components/Contacts";
import FooterLinks from "../components/FooterLinks";
import FooterLink from "../components/FooterLink";
import FooterLinkDivider from "../components/FooterLinkDivider";

import { THEME } from "../constants/app";

const Container = styled(Card)({
  margin: "30px",
  padding: "10px 30px 15px 30px",
  [THEME.breakpoints.down("sm")]: {
    margin: "15px",
    padding: "0px 15px",
  },
});

const Header = styled.h2({
  textAlign: "center",
});

const Subheader = styled.h3({
  paddingTop: "15px",
});

const UpdatedAt = styled.h4({
  textAlign: "center",
  paddingTop: "15px",
});

const TermsAndConditions = () => {
  return (
    <>
      <Container>
        <Header>Terms and Conditions</Header>
        <p>
          BollyCards (created by Jai Sughand - "we", "us", or "our") is a
          free-to-play online multiplayer game. The BollyCards app and website
          (collectively, "Service") is provided by Jai Sughand and is intended
          for use as is.
        </p>
        <p>
          By downloading or using our Service, these Terms and Conditions will
          automatically apply to you. If you do not agree to them, you must
          cease access and use of our Service immediately.
        </p>
        <Subheader>Intellectual Property Rights</Subheader>
        <p>
          The Service itself, and all the trademarks, service marks, brand
          names, logos, domain names, copyright, design rights, software rights,
          database rights, and other intellectual property rights related to it
          are owned by or licensed to Jai Sughand.
        </p>
        <Subheader>Prohibited Activities</Subheader>
        <p>
          There are certain activities you are not allowed to perform, which may
          lead to suspension or cancellation of your access and use of our
          Service. This includes:
        </p>
        <ul>
          <li>
            modifying, translating, reverse engineering, deciphering,
            decompiling, disassembling the Service or any part of it unless you
            are specifically allowed by applicable law
          </li>
          <li>
            creating, using, and/or distributing cheats, exploits, bots, mods,
            hacks, spyware, extraction tools, or other software that interact
            with or affect the Service in any way
          </li>
          <li>
            interfering with, disrupting, or creating an undue burden on the
            Service
          </li>
          <li>attempting to bypass any security measures of the Service</li>
          <li>infringing any intellectual property rights owned by us</li>
        </ul>
        <Subheader>Responsibilities and Liabilities</Subheader>
        <p>
          We need to collect certain Personal Information that allows our
          Service to function. It is your responsibility to keep your device and
          access to the Service secure. Therefore, we recommend that you do not
          jailbreak or root your device, which is the process of removing
          software restrictions and limitations imposed by the official
          operating system of your device. It could make your device vulnerable
          to malware/viruses/malicious programs, compromise your device’s
          security features, and cause the Service to not work properly or at
          all.
        </p>
        <p>
          Our Service requires an active internet connection to work at full
          functionality. We recommend that you have a stable and secure internet
          connection. It is your responsibility to pay for any internet costs
          incurred while using our Service.
        </p>
        <p>
          We use third-party services for our Service to function. Although we
          strive to ensure that the Service is behaving correctly and is
          available at all times, we accept no liability for any issues or loss
          you experience due to the fault of our third-party service providers
          or us.
        </p>
        <p>
          Links to the Terms and Conditions of our third-party service
          providers:
        </p>
        <ul>
          <li>
            <Link
              color="error"
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play Services
            </Link>
          </li>
          <li>
            <Link
              color="error"
              href="https://developers.google.com/admob/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              AdMob
            </Link>
          </li>
          <li>
            <Link
              color="error"
              href="https://firebase.google.com/terms/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </Link>
          </li>
          <li>
            <Link
              color="error"
              href="https://firebase.google.com/terms/crashlytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </Link>
          </li>
        </ul>
        <Subheader>Changes to our Service</Subheader>
        <p>
          We are committed to constantly improve our Service. For that reason,
          we may make changes to the Service or charge you for it at any time.
          We will never charge you for the Service without making it very clear
          to you what you are paying for.
        </p>
        <p>
          We may also update our app occasionally. The app is currently
          available on Android and iOS – the requirements for both systems may
          change, and you will need to download the updates if you want to keep
          using the app. We may not always update the app so that it is relevant
          to you and/or works with the Android or iOS version that you have
          installed on your device. However, you must always accept updates to
          the app when offered to you.
        </p>
        <p>
          For some reason, we may stop providing the Service and may terminate
          the use of it at any time without giving notice of termination to you.
          Unless we tell you otherwise, upon any termination, the rights and
          licenses granted to you in these Terms and Conditions will end and you
          must cease access and use of our Service immediately.
        </p>
        <Subheader>Changes to this Terms and Conditions</Subheader>
        <p>
          We may update our Terms and Conditions from time to time. Thus, you
          are advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Terms and Conditions on
          this page.
        </p>
        <Subheader>Contact us</Subheader>
        <p>
          If you have any questions or suggestions about our Terms and
          Conditions, please contact us at{" "}
          <Link
            color="error"
            href="mailto:bollycards@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            bollycards@gmail.com
          </Link>
          .
        </p>
        <UpdatedAt>Last updated on September 24, 2020</UpdatedAt>
      </Container>
      <Contacts />
      <FooterLinks>
        <FooterLink to="/">Home</FooterLink>
        <FooterLinkDivider />
        <FooterLink to="/privacy">Privacy</FooterLink>
      </FooterLinks>
    </>
  );
};

export default TermsAndConditions;
