import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import MaterialLink from "@material-ui/core/Link";

const FooterLink = styled(MaterialLink).attrs({
  color: "error",
  component: RouterLink,
})({});

export default FooterLink;
