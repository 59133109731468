import React from "react";
import styled from "styled-components";
import Link from "@material-ui/core/Link";

import googlePlayBadge from "../images/GooglePlayBadge.svg";
import appStoreBadge from "../images/AppStoreBadge.svg";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "2px 0px",
});

const DownloadLink = styled(Link)({
  width: "180px",
  ":not(:first-child)": {
    marginTop: "10px",
  },
});

const DownloadImage = styled.img({
  height: "100%",
  width: "100%",
});

const DownloadLinks = () => (
  <Container>
    <DownloadLink
      href="https://play.google.com/store/apps/details?id=com.bollycards"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadImage src={googlePlayBadge} alt="Get it on Google Play" />
    </DownloadLink>
    <DownloadLink
      href="https://apps.apple.com/app/bollycards/id1459416262"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DownloadImage src={appStoreBadge} alt="Download on the App Store" />
    </DownloadLink>
  </Container>
);

export default DownloadLinks;
