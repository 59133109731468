import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

import ScrollToTop from "../components/ScrollToTop";
import Header from "../components/Header";

const Container = styled.div({
  paddingBottom: "env(safe-area-inset-bottom)",
});

const App = () => {
  return (
    <Container>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/privacy">
            <Header />
            <PrivacyPolicy />
          </Route>
          <Route path="/terms">
            <Header />
            <TermsAndConditions />
          </Route>
          <Route>
            <Home />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
};

export default App;
