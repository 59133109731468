import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Clipboard from "clipboard";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import Header from "../components/Header";
import DownloadLinks from "../components/DownloadLinks";
import Contacts from "../components/Contacts";
import FooterLinks from "../components/FooterLinks";
import FooterLink from "../components/FooterLink";
import FooterLinkDivider from "../components/FooterLinkDivider";

import { THEME, SECONDARY_COLOR } from "../constants/app";

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "30px",
  [THEME.breakpoints.down("sm")]: {
    margin: "15px",
  },
});

const PartyCodeContainer = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "500px",
  padding: "10px 30px",
  [THEME.breakpoints.down("sm")]: {
    padding: "0px 15px",
  },
});

const PartyCodeButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  margin: "2px 0px",
});

const PartyCodeHeader = styled.h2({
  textAlign: "center",
});

const PartyCodeSubheader = styled.p({
  textAlign: "center",
});

const PartyCode = styled.div(({ isClipboardSupported }) => ({
  display: "flex",
  alignItems: "center",
  height: "36px",
  padding: isClipboardSupported ? "0px 12px 0px 15px" : "0px 18px",
  borderRadius: isClipboardSupported ? "18px 0px 0px 18px" : "18px",
  fontSize: "1.05rem",
  backgroundColor: SECONDARY_COLOR,
}));

const CopyButton = styled(Button)({
  minWidth: "80px",
  paddingLeft: "8px",
  paddingRight: "12px",
  borderRadius: "0px 18px 18px 0px",
  borderLeft: "1px solid black",
});

const DownloadContainer = styled(PartyCodeContainer)({
  marginTop: "30px",
  [THEME.breakpoints.down("sm")]: {
    marginTop: "15px",
  },
});

const DownloadHeader = styled.h3({
  textAlign: "center",
});

const DownloadSubheader = styled.p({
  textAlign: "center",
});

const PartyJoin = ({ partyCode }) => {
  const isClipboardSupported = Clipboard.isSupported();
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (isClipboardSupported) {
      const clipboard = new Clipboard("#copy-button");

      clipboard.on("success", (event) => {
        event.clearSelection();
        setCopyButtonText("Copied");
        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(() => setCopyButtonText("Copy"), 15000));
      });

      return () => {
        clearTimeout(timeoutId);
        clipboard.destroy();
      };
    }
  }, [isClipboardSupported, timeoutId]);

  return (
    <>
      <Header />
      <Wrapper>
        <PartyCodeContainer>
          <PartyCodeHeader>Party Code</PartyCodeHeader>
          <PartyCodeButtonContainer>
            <PartyCode
              id="party-code"
              isClipboardSupported={isClipboardSupported}
            >
              {partyCode}
            </PartyCode>
            {isClipboardSupported && (
              <CopyButton
                id="copy-button"
                variant="contained"
                data-clipboard-target="#party-code"
              >
                {copyButtonText}
              </CopyButton>
            )}
          </PartyCodeButtonContainer>
          <PartyCodeSubheader>
            To play, launch the app and join party using the code above.
          </PartyCodeSubheader>
        </PartyCodeContainer>
        <DownloadContainer>
          <DownloadHeader>Don't have the app yet?</DownloadHeader>
          <DownloadLinks />
          <DownloadSubheader>
            Idhar kya dekh rahe ho, jaldi download karke khelo!
          </DownloadSubheader>
        </DownloadContainer>
      </Wrapper>
      <Contacts />
      <FooterLinks>
        <FooterLink to="/">Home</FooterLink>
        <FooterLinkDivider />
        <FooterLink to="/privacy">Privacy</FooterLink>
        <FooterLinkDivider />
        <FooterLink to="/terms">Terms</FooterLink>
      </FooterLinks>
    </>
  );
};

PartyJoin.propTypes = {
  partyCode: PropTypes.string.isRequired,
};

export default PartyJoin;
