import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";

import Contacts from "../components/Contacts";
import FooterLinks from "../components/FooterLinks";
import FooterLink from "../components/FooterLink";
import FooterLinkDivider from "../components/FooterLinkDivider";

import { THEME } from "../constants/app";

const Container = styled(Card)({
  margin: "30px",
  padding: "10px 30px 15px 30px",
  [THEME.breakpoints.down("sm")]: {
    margin: "15px",
    padding: "0px 15px",
  },
});

const Header = styled.h2({
  textAlign: "center",
});

const Subheader = styled.h3({
  paddingTop: "15px",
});

const UpdatedAt = styled.h4({
  textAlign: "center",
  paddingTop: "15px",
});

const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <Header>Privacy Policy</Header>
        <p>
          BollyCards (created by Jai Sughand - "we", "us", or "our") is a
          free-to-play online multiplayer game. The BollyCards app and website
          (collectively, "Service") is provided by Jai Sughand and is intended
          for use as is.
        </p>
        <p>
          This Privacy Policy explains how we collect, use, and disclose your
          Personal Information and how you can exercise your privacy rights.
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this Privacy Policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <Subheader>Data collected for our Service to function</Subheader>
        <p>
          We need to collect certain data that allows our Service to function.
          This includes:
        </p>
        <ul>
          <li>
            Your Nickname (shown to other players you choose to play with)
          </li>
          <li>Your consents (for using our Service and additional features)</li>
          <li>
            Data about your use of our Service (such as actions, progress,
            gameplay, and interactions with other players)
          </li>
          <li>
            Data about your device (such as operating system, screen dimensions,
            and internet connection state)
          </li>
        </ul>
        <p>
          Links to the privacy policy of third-party services used for our
          Service to function:
        </p>
        <ul>
          <li>
            <Link
              color="error"
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Play
            </Link>
          </li>
          <li>
            <Link
              color="error"
              href="https://www.apple.com/legal/privacy/en-ww/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apple
            </Link>
          </li>
        </ul>
        <Subheader>Data collected for Analytics</Subheader>
        <p>
          We use third-party services to collect analytics data and crash
          reports for our app. This includes the device information, behavior,
          engagement, demographics, and IP address in an anonymous form. We use
          these analytics to constantly improve the game experience and
          stability. We will ensure that we obtain prior consent in accordance
          with any applicable laws in the country you reside in.
        </p>
        <p>
          Links to the privacy policy of third-party services used for
          analytics:
        </p>
        <ul>
          <li>
            <Link
              color="error"
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </Link>
          </li>
          <li>
            <Link
              color="error"
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </Link>
          </li>
        </ul>
        <Subheader>Data collected for Ads</Subheader>
        <p>
          We use third-party services to show ads in our app from time to time.
          These services may show personalized ads that are more relevant to you
          by collecting data and using a unique identifier on your device. We
          will ensure that we obtain prior consent in accordance with any
          applicable laws in the country you reside in. You may enable/disable
          personalized ads at any time through the privacy settings on your
          device. If you choose to disable it, the ads shown to you may not be
          relevant to you.
        </p>
        <p>Links to the privacy policy of third-party services used for ads:</p>
        <ul>
          <li>
            <Link
              color="error"
              href="https://support.google.com/admob/answer/6128543?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              AdMob
            </Link>
          </li>
        </ul>
        <Subheader>Website Cookies</Subheader>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </p>
        <p>
          Our website does not use Cookies explicitly. However, we use
          third-party services that use Cookies to collect web analytics data.
          This includes the device/browser information, behavior, engagement,
          demographics, and IP address in an anonymous form. We use these
          analytics to constantly improve our website. You may enable/disable
          these Cookies at any time through your browser settings.
        </p>
        <p>
          Links to the privacy policy of third-party services used to collect
          this data:
        </p>
        <ul>
          <li>
            <Link
              color="error"
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics
            </Link>
          </li>
        </ul>
        <Subheader>Push Notification</Subheader>
        <p>
          We may send you Push Notifications from time to time for game updates
          and marketing purposes. You may enable/disable it at any time through
          your device settings.
        </p>
        <Subheader>Data Retention</Subheader>
        <p>
          We retain your Personal Information for as long as needed to provide
          you our Service or when we have legitimate reasons such as to comply
          with our legal obligations, resolve disputes, and enforce our
          agreements. We will delete the data once we do not have any such
          reason to retain it.
        </p>
        <Subheader>Data Transfer</Subheader>
        <p>
          Your Personal Information may be transferred to and processed in
          countries other than the one you reside in. Please note that these
          countries may have different privacy laws applicable. When we transfer
          your Personal Information internationally, we will take reasonable
          steps to ensure that it is treated securely, lawfully, and in
          accordance with this Privacy Policy.
        </p>
        <Subheader>Data Security</Subheader>
        <p>
          We secure your Personal Information on computer servers in a
          controlled, secure environment, protected from unauthorized access,
          use, or disclosure. We maintain reasonable administrative, technical,
          and physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of Personal Information in
          its control and custody. However, no data transmission over the
          Internet or wireless network can be guaranteed. Therefore, while we
          strive to protect your Personal Information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control; (ii) the security, integrity, and privacy of any
          information and data exchanged between you and our Service cannot be
          guaranteed; and (iii) any such information and data may be viewed or
          tampered with in transit by a third-party, despite best efforts.
        </p>
        <Subheader>Data Breach</Subheader>
        <p>
          In the event we become aware that the security of our Service has been
          compromised or users Personal Information has been disclosed to
          unrelated third parties as a result of external activity, including,
          but not limited to, security attacks or fraud, we reserve the right to
          take reasonably appropriate measures, including, but not limited to,
          investigation and reporting, as well as notification to and
          cooperation with law enforcement authorities. In the event of a data
          breach, we will make reasonable efforts to notify affected individuals
          if we believe that there is a reasonable risk of harm to the user as a
          result of the breach or if notice is otherwise required by law. When
          we do, we will post a notice in the Service.
        </p>
        <Subheader>Data Privacy Rights</Subheader>
        <p>
          If you would like to exercise your privacy rights, please contact us
          with your request. We will respond to that request in accordance with
          any applicable laws in the country you reside in. You must include
          sufficient details to allow us to properly understand the request and
          respond to it. Please note that we cannot respond to your request or
          provide you with Personal Information unless we first verify your
          identity or authority to make such a request and confirm that the
          Personal Information relates to you.
        </p>
        <Subheader>Children’s Privacy</Subheader>
        <p>
          Our Service does not address anyone under the age of 16. We do not
          knowingly collect personally identifiable information from children
          under 16. In the case we discover that a child under 16 has provided
          us with Personal Information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with Personal Information, please contact us so
          that we will be able to do the necessary actions.
        </p>
        <Subheader>Links to other sites</Subheader>
        <p>
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <Subheader>Changes to this Privacy Policy</Subheader>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>
        <Subheader>Contact us</Subheader>
        <p>
          If you have any questions or suggestions about our Privacy Policy or
          would like to exercise your privacy rights, please contact us at{" "}
          <Link
            color="error"
            href="mailto:bollycards@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            bollycards@gmail.com
          </Link>
          .
        </p>
        <UpdatedAt>Last updated on September 24, 2020</UpdatedAt>
      </Container>
      <Contacts />
      <FooterLinks>
        <FooterLink to="/">Home</FooterLink>
        <FooterLinkDivider />
        <FooterLink to="/terms">Terms</FooterLink>
      </FooterLinks>
    </>
  );
};

export default PrivacyPolicy;
