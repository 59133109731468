import { createMuiTheme } from "@material-ui/core";

// Colors
export const BG_COLOR = "#102030";
export const PAPER_BG_COLOR = "#203040";
export const PRIMARY_COLOR = "#1a2a3a";
export const SECONDARY_COLOR = "#bb0011";
export const TERTIARY_COLOR = "#f4f4f4";
export const DISABLED_COLOR = "#555555";
export const INACTIVE_COLOR = "#90a0b0";
export const ERROR_COLOR = "#ed2f2f";

export const DARK_TEXT_COLOR = "#000000";
export const DARK_GREY_TEXT_COLOR = DISABLED_COLOR;
export const LIGHT_TEXT_COLOR = TERTIARY_COLOR;
export const LIGHT_GREY_TEXT_COLOR = "#c4c4c4";

export const THEME = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "::selection": {
          backgroundColor: INACTIVE_COLOR,
          color: TERTIARY_COLOR,
        },
      },
    },
  },
  palette: {
    type: "dark",
    background: {
      default: BG_COLOR,
      paper: PAPER_BG_COLOR,
    },
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
      main: ERROR_COLOR,
    },
  },
});
