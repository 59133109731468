import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Card from "@material-ui/core/Card";
import DragScroll from "react-indiana-drag-scroll";

import PartyJoin from "./PartyJoin";

import DownloadLinks from "../components/DownloadLinks";
import Contacts from "../components/Contacts";
import FooterLinks from "../components/FooterLinks";
import FooterLink from "../components/FooterLink";
import FooterLinkDivider from "../components/FooterLinkDivider";

import titleLogo from "../images/BollyCardsTitle.png";
import appScreenshot1 from "../images/AppScreenshot_1.png";
import appScreenshot2 from "../images/AppScreenshot_2.png";
import appScreenshot3 from "../images/AppScreenshot_3.png";
import appScreenshot4 from "../images/AppScreenshot_4.png";
import appScreenshot5 from "../images/AppScreenshot_5.png";
import appScreenshot6 from "../images/AppScreenshot_6.png";
import appScreenshot7 from "../images/AppScreenshot_7.png";
import appScreenshot8 from "../images/AppScreenshot_8.png";
import { THEME } from "../constants/app";

const Wrapper = styled.div({});

const Container = styled.div({
  padding: "50px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Header = styled.div({
  width: "80%",
  maxWidth: "400px",
  [THEME.breakpoints.down("sm")]: {
    maxWidth: "300px",
  },
});

const HeaderLogo = styled.img({
  height: "100%",
  width: "100%",
});

const Subheader = styled.h2({
  paddingTop: "10px",
  textAlign: "center",
});

const DownloadContainer = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "500px",
  marginTop: "30px",
  padding: "10px 30px 15px 30px",
  [THEME.breakpoints.down("sm")]: {
    marginTop: "20px",
    padding: "0px 15px 10px 15px",
  },
});

const DownloadHeader = styled.h3({
  textAlign: "center",
});

const Screenshots = styled(DragScroll)({
  overflow: "auto",
  whiteSpace: "nowrap",
  marginBottom: "40px",
  [THEME.breakpoints.down("sm")]: {
    marginBottom: "30px",
  },
});

const Screenshot = styled.img({
  display: "inline-block",
  height: "100%",
  width: "80%",
  maxWidth: "360px",
  marginRight: "40px",
  ":first-child": {
    marginLeft: "40px",
  },
  [THEME.breakpoints.down("sm")]: {
    marginRight: "20px",
    ":first-child": {
      marginLeft: "20px",
    },
  },
  boxShadow: [THEME.shadows[1]],
});

const Home = () => {
  const { search } = useLocation();
  const partyCode = new URLSearchParams(search).get("partyCode");

  return partyCode ? (
    <PartyJoin partyCode={partyCode} />
  ) : (
    <Wrapper>
      <Container>
        <Header>
          <HeaderLogo src={titleLogo} alt="BollyCards" />
        </Header>
        <Subheader>A besharam party game for Bollywood keedas</Subheader>
        <DownloadContainer>
          <DownloadHeader>Download and play abhi ke abhi!</DownloadHeader>
          <DownloadLinks />
        </DownloadContainer>
      </Container>
      <Screenshots vertical={false}>
        <Screenshot src={appScreenshot1} alt="App screenshot 1" />
        <Screenshot src={appScreenshot2} alt="App screenshot 2" />
        <Screenshot src={appScreenshot3} alt="App screenshot 3" />
        <Screenshot src={appScreenshot4} alt="App screenshot 4" />
        <Screenshot src={appScreenshot5} alt="App screenshot 5" />
        <Screenshot src={appScreenshot6} alt="App screenshot 6" />
        <Screenshot src={appScreenshot7} alt="App screenshot 7" />
        <Screenshot src={appScreenshot8} alt="App screenshot 8" />
      </Screenshots>
      <Contacts />
      <FooterLinks>
        <FooterLink to="/privacy">Privacy</FooterLink>
        <FooterLinkDivider />
        <FooterLink to="/terms">Terms</FooterLink>
      </FooterLinks>
    </Wrapper>
  );
};

export default Home;
