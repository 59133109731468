import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const Container = styled(Typography).attrs({
  color: "error",
  variant: "h5",
})({
  padding: "3px 10px 0px 10px",
});

const FooterLinkDivider = () => <Container>•</Container>;

export default FooterLinkDivider;
